import { colors, fontSizes, space, fontWeights, COLOR_CONSTANTS } from 'theme'
import logoBig from '../../static/svg/logo_big.svg'

export const joinTemplate = `
  <div style="display: flex; align-items: center; justify-content: center; width: 100%; border-top: 1px solid ${COLOR_CONSTANTS.COSMIC_ENERGY};border-bottom: 1px solid ${COLOR_CONSTANTS.COSMIC_ENERGY}; margin: ${space.xl} 0;">
    <div style="display: flex; flex-direction: column; align-items: center; margin: ${space.l} auto; padding: ${space.m} 0;">
        <div style="display: flex;  align-items: center; justify-content: center; margin-bottom: ${space.m};">
            <span style="font-size: ${fontSizes.s}; font-weight: ${fontWeights.bold}; color: ${colors.secondaryText}; text-transform: uppercase;">
                Brought to you by
            </span>
            <img src="${logoBig}" alt="Try Vista Social for Free" style="max-width: 180px; margin-left: ${space.m}"/>
        </div>
        <p style="font-size: ${fontSizes.xxl}; color: ${COLOR_CONSTANTS.DENIM}; font-weight: ${fontWeights.bold}; margin: 0;">Try Vista Social for Free</p>
        <p style="font-size: ${fontSizes.m}; color: ${colors.secondaryText}; text-align: center;">A social media management platform that actually helps you grow with easy-to-use content planning, scheduling, engagement and analytics tools.</p>
        <a href="/pricing" class="join-template_button">Get Started Now</a>           
    </div>
</div>
`

export const blogContentAddingJoinTemplate = ({ content }) => {
  let updatedContent
  if (content) {
    const split = content.split('\n')
    const after = Math.round(split.length / 1.25)
    updatedContent = `${split.slice(0, after).join('\n')}\n${joinTemplate}\n${split.slice(after).join('\n')}`
  }

  return updatedContent
}
